import React from "react";
import { UserAuth } from "../context/Auth";
import { useNavigate } from "react-router-dom";

const LogoutButton = () => {
  const { logout } = UserAuth();

  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/login");
    } catch (error) {}
  };

  return (
    <div style={{ paddingLeft: 20, paddingBottom: 20 }}>
      <button onClick={handleLogout}>Log Out</button>
    </div>
  );
};

export default LogoutButton;
