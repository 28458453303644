import React from "react";
import { useNavigate } from "react-router-dom";

const SignUpButton = () => {
  const navigate = useNavigate();

  const handleSignUp = async () => {
    try {
      navigate("/signup");
    } catch (error) {}
  };
  return (
    <div style={{ paddingLeft: 20, paddingBottom: 20 }}>
      <button onClick={handleSignUp}>Sign Up New User</button>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      ></div>
    </div>
  );
};

export default SignUpButton;
