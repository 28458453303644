import { useState, useEffect } from "react";
import Select from "react-select";
import { getDocs, collection, where, query } from "firebase/firestore";
import { ref, getDownloadURL } from "firebase/storage";
import { db, storage } from "../firebase_setup/firebase.config";
import NoImageAvailable from "../assets/NoImageAvailable.png";
import { motion } from "framer-motion";
import SplatterLogoDropdown from "./SplatterLogoDropdown";
import PKFoundationFooter from "./PKFoundationFooter";
import PollockProjectLogo from "./PollockProjectLogo";
import ImageModal from "./ImageModal";
import SearchIcon from "@mui/icons-material/Search";
import MobileHeader from "./MobileHeader";
import "../CSS/responsive.css";
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";
import PollockLooking from "../assets/PollockLooking.webp";

const ArtworkLookUp = () => {
  const [keyword, setKeyword] = useState("");
  const [input, setInput] = useState("");
  const [error, setError] = useState(false);
  const [results, setResults] = useState([]);
  const [searchedForArtworks, setSearchedForArtworks] = useState([]);
  const [imageClicked, setImagedClicked] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [individualImageUrl, setIndividualUrl] = useState(null);
  const [hovering, setHovering] = useState(false);
  const [showStaticImage, setShowStaticImage] = useState(true);
  const [foundState, setFoundState] = useState(null);

  const options = [
    { value: "title", label: "Title" },
    { value: "museum", label: "Museum" },
    { value: "city", label: "City" },
    { value: "state", label: "State" },
    { value: "country", label: "Country" },
    { value: "year", label: "Year" },
    { value: "medium", label: "Medium" },
  ];

  useEffect(() => {
    async function fetchData() {
      const pollockWorksRef = collection(db, "pollockWorks");
      const workInfo = await query(
        pollockWorksRef,
        where("isArtwork", "==", true)
      );

      const querySnapshot = await getDocs(workInfo);

      const promises = querySnapshot.docs.map(async (doc) => {
        const individualWork = doc.data();
        let url = individualWork.imagePath
          ? await getDownloadURL(ref(storage, individualWork.imagePath))
          : "";

        return {
          id: doc.id,
          ...doc.data(),
          imageUrl: url,
        };
      });

      let finalPromise = await Promise.all(promises);
      setResults(finalPromise);
    }
    fetchData();
  }, []);

  const handleChange = (value) => {
    setInput(value);
  };

  const handleQuery = async (e) => {
    e.preventDefault();
    try {
      let searchedWorks = results.filter((artwork) =>
        artwork[keyword.value].toLowerCase().includes(input.toLowerCase())
      );
      setShowStaticImage(false);
      setSearchedForArtworks(searchedWorks);
      setInput("");
      setKeyword("");
      setFoundState();

      if (searchedWorks.length === 0) {
        setError(true);
        setShowStaticImage(true);
      }
    } catch (caughtError) {
      console.log(caughtError);
    }
  };

  const handleEnlarge = (result, index) => {
    setCurrentIndex(index);
    setImagedClicked(!imageClicked);
    setIndividualUrl(result.imageUrl);
  };

  const returnToNull = () => {
    setImagedClicked(false);
    setCurrentIndex(null);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 50 }}
      transition={{ duration: 2 }}
      exit={{ opacity: 0 }}
    >
      <div className='mobile-wrapper'>
        <MobileHeader />
        <div className='mobile-select'>
          <Select
            defaultValue={"keyword"}
            value={keyword}
            onChange={(e) => {
              setKeyword(e);
              setError(false);
            }}
            options={options}
          />
        </div>
        <div className='input-and-icon'>
          <form onSubmit={handleQuery}>
            <input
              value={input}
              className='mobile-input'
              onChange={(e) => handleChange(e.target.value)}
            />
          </form>
          <div onClick={handleQuery}>
            <SearchIcon sx={{ fontSize: "34px" }} />
          </div>
        </div>
        {error && searchedForArtworks.length === 0 && (
          <h2
            style={{
              color: "red",
              paddingLeft: 18,
              paddingTop: 10,
            }}
          >
            Artworks not found, please try again.
          </h2>
        )}
        {searchedForArtworks && searchedForArtworks.length > 0 && (
          <div className='image-container'>
            <ul>
              {searchedForArtworks.map((result, index) => {
                return (
                  <div key={result.id}>
                    <div className='image-and-text'>
                      <div
                        style={{
                          marginTop: 20,
                          marginBottom: 20,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          className='image'
                          src={result.imageUrl}
                          onError={(e) => {
                            e.target.src = NoImageAvailable;
                          }}
                          onClick={() => handleEnlarge(result, index)}
                          alt={"Not Available"}
                          object-fit='cover'
                          onDragStart={(e) => e.preventDefault()}
                        />
                      </div>
                      <div className='artwork-text'>
                        <div>
                          <h1
                            style={{
                              fontStyle: "italic",
                            }}
                          >
                            <b>{result.title}</b>
                          </h1>
                        </div>
                        <ul className='unordered'>
                          <li>Year –– {result.year}</li>
                          <li>Medium –– {result.medium}</li>
                          <li>Dimensions –– {result.dimensions}</li>
                          <li>
                            Museum ––
                            <a
                              className='museum-link'
                              href={result.link}
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              {result.museum}
                            </a>
                          </li>
                          <li>
                            Location –– {result.city}, {result.state},{" "}
                            {result.country}
                          </li>
                          <li>
                            On View –– {result.onView === true ? "Yes" : "No"}
                          </li>
                        </ul>
                        <div className='PK-copyright'>
                          &copy;The Pollock-Krasner Foundation / Artists Rights
                          Society (ARS), New York
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </ul>
          </div>
        )}
      </div>
      {/* //////////////////////////mobile up desktop down ////////////////////// */}
      <>
        <div className='desktop-wrapper'>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              backgroundColor: "white",
              position: "fixed",
              zIndex: "1",
              width: "100vw",
            }}
          >
            <SplatterLogoDropdown background={"white"} />

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                padding: 20,
                paddingTop: 40,
                marginLeft: 110,
                backgroundColor: "white",
              }}
            >
              <div style={{ width: "280px" }}>
                <Select
                  defaultValue={"keyword"}
                  value={keyword}
                  onChange={(e) => {
                    setKeyword(e);
                    setError(false);
                  }}
                  options={options}
                />
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <form onSubmit={handleQuery}>
                  <input
                    value={input}
                    style={{
                      height: "14px",
                      width: "240px",
                      padding: "10px",
                      marginLeft: "25px",
                    }}
                    onChange={(e) => handleChange(e.target.value)}
                  />
                </form>
                {error && searchedForArtworks.length === 0 && (
                  <h2
                    style={{
                      color: "red",
                      paddingLeft: 18,
                      paddingTop: 10,
                    }}
                  >
                    Artworks not found, please try again.
                  </h2>
                )}
              </div>
              <div
                style={{
                  marginTop: "1px",
                  width: "37px",
                  height: "37px",
                  backgroundColor: "white",
                  display: "flex",
                  alignContent: "center",
                  marginLeft: "2px",
                }}
                onClick={handleQuery}
              >
                <SearchIcon sx={{ fontSize: "34px" }} />
              </div>
            </div>
            <div style={{ paddingRight: "30px", paddingTop: "30px" }}>
              <PollockProjectLogo />
            </div>
          </div>
          {showStaticImage && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                src={PollockLooking}
                alt='action-painting'
                style={{
                  border: "1px solid red",
                  width: "100vw",
                  position: "fixed",
                  marginTop: "110px",
                }}
              />
            </div>
          )}
          ;
          {searchedForArtworks && searchedForArtworks.length > 0 && (
            <div style={{ paddingTop: "80px", paddingBottom: "80px" }}>
              <div
                style={{
                  marginTop: "50px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <h3 style={{ color: "blue" }}>
                  There {searchedForArtworks.length === 1 ? "was" : "were"}{" "}
                  {searchedForArtworks.length} Jackson Pollock artworks found
                </h3>
              </div>
              <ul>
                {searchedForArtworks.map((result, index) => {
                  return (
                    <div key={result.id}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingTop: "30px",
                        }}
                      >
                        <div
                          style={{
                            marginTop: 20,
                            marginBottom: 20,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={result.imageUrl}
                            onError={(e) => {
                              e.target.src = NoImageAvailable;
                            }}
                            onClick={() => handleEnlarge(result, index)}
                            alt={"Not Available"}
                            object-fit='cover'
                            onDragStart={(e) => e.preventDefault()}
                            onMouseEnter={() => {
                              setHovering(true);
                              setCurrentIndex(index);
                            }}
                            onMouseOut={() => setHovering(false)}
                            style={{
                              userDrag: "none",
                              maxWidth: 400,
                              height: "auto",
                              border:
                                hovering && currentIndex === index
                                  ? "2px solid blue"
                                  : "2px solid transparent",
                              padding: 3,
                            }}
                          />
                        </div>
                        <div
                          style={{ paddingLeft: "480px", marginTop: "20px" }}
                        >
                          <div>
                            <h1
                              style={{
                                fontStyle: "italic",
                                paddingBottom: "20px",
                              }}
                            >
                              <b>{result.title}</b>
                            </h1>
                          </div>
                          <ul style={{ paddingLeft: "40px" }}>
                            <li
                              style={{
                                paddingBottom: "3px",
                                fontSize: "15px",
                                fontFamily: "Times New Roman",
                              }}
                            >
                              Year –– {result.year}
                            </li>
                            <li
                              style={{
                                paddingBottom: "3px",
                                fontSize: "15px",
                                fontFamily: "Times New Roman",
                              }}
                            >
                              Medium –– {result.medium}
                            </li>
                            <li
                              style={{
                                paddingBottom: "3px",
                                fontSize: "15px",
                                fontFamily: "Times New Roman",
                              }}
                            >
                              Dimensions –– {result.dimensions}
                            </li>
                            <li
                              style={{
                                paddingBottom: "3px",
                                fontSize: "15px",
                                fontFamily: "Times New Roman",
                              }}
                            >
                              Museum ––{" "}
                              <a
                                href={result.link}
                                target='_blank'
                                rel='noopener noreferrer'
                                style={{
                                  color: "#0000FF",
                                  textDecoration: "none",
                                }}
                              >
                                {result.museum}
                              </a>
                            </li>
                            <li
                              style={{
                                paddingBottom: "3px",
                                fontSize: "15px",
                                fontFamily: "Times New Roman",
                              }}
                            >
                              Location –– {result.city}, {result.state},{" "}
                              {result.country}
                            </li>
                            <li
                              style={{
                                paddingBottom: "3px",
                                fontSize: "15px",
                                fontFamily: "Times New Roman",
                              }}
                            >
                              On View –– {result.onView === true ? "Yes" : "No"}
                            </li>
                          </ul>
                          <div
                            style={{
                              paddingTop: "20px",
                              fontSize: "12px",
                              fontFamily: "Times New Roman",
                            }}
                          >
                            &copy; The Pollock-Krasner Foundation / Artists
                            Rights Society (ARS), New York
                          </div>
                        </div>
                      </div>
                      {imageClicked && currentIndex === index && (
                        <ImageModal
                          returnToNull={returnToNull}
                          individualImageUrl={individualImageUrl}
                          setImagedClicked={setImagedClicked}
                          setCUrrentIndex={setCurrentIndex}
                        />
                      )}
                    </div>
                  );
                })}
              </ul>
              <div style={{ marginTop: "40px" }}>
                <p
                  style={{
                    fontSize: "12px",
                    paddingLeft: "46px",
                    fontFamily: "Helvetica",
                  }}
                >
                  &copy; 2024 The<b>Pollock</b>Project — All Rights Reserved
                </p>
              </div>
            </div>
          )}
          {showStaticImage && (
            <div>
              <PKFoundationFooter background='white' noText />
            </div>
          )}
        </div>
        {searchedForArtworks.length > 1 && (
          <div
            style={{
              rotate: "-90deg",
              zIndex: 10,
              position: "fixed",
              bottom: "40px",
              right: "288px",
            }}
            onClick={scrollToTop}
          >
            <KeyboardTabIcon fontSize='large' style={{ color: "blue" }} />
          </div>
        )}
      </>
    </motion.div>
  );
};

export default ArtworkLookUp;
