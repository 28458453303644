import { useState } from "react";
import JacksonWholeImageBlack from "../assets/jackson_whole_logo_main_black.png";
import JacksonWHolePinImageBLack from "../assets/jackson_whole_logo_main_pin_black.png";
import SplatterLogoDropdown from "./SplatterLogoDropdown";

const Whole = () => {
  const [currentWholeImage, setCurrentWHoleImage] = useState(
    JacksonWholeImageBlack
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <SplatterLogoDropdown />

      <div style={{ paddingRight: "30px", paddingTop: "30px" }}>
        <img
          src={currentWholeImage}
          alt='whole_logo'
          onMouseEnter={() => setCurrentWHoleImage(JacksonWHolePinImageBLack)}
          onMouseOut={() => setCurrentWHoleImage(JacksonWholeImageBlack)}
          style={{ height: "40px" }}
        />
      </div>
    </div>
  );
};

export default Whole;
