import React from "react";
import PollockProjectLogoImage from "../assets/pollock_project_logo_main_black.png";
import PollockProjectLogoImageWhite from "../assets/pollock_project_logo_main.png";

const PollockProjectLogo = ({ logoColor, logoHeight }) => {
  return (
    <div>
      <img
        alt='PP_logo'
        src={logoColor ? PollockProjectLogoImageWhite : PollockProjectLogoImage}
        style={{ height: logoHeight || "46px" }}
      />
    </div>
  );
};

export default PollockProjectLogo;
