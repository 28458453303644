import "../CSS/App.css";
import React from "react";
import { useState } from "react";
import { UserAuth } from "../context/Auth";
import { useNavigate } from "react-router-dom";

const SignUp = () => {
  const [email, updateEmail] = useState("");
  const [error, updateError] = useState("");
  const [password, updatePassword] = useState("");

  const { createUser } = UserAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    updateError("");
    try {
      await createUser(email, password);
      navigate("/login");
    } catch (error) {
      updateError(`Error signing up new user | ${error}`);
    }
  };

  const handleBackToInput = () => {
    try {
      navigate("/input");
    } catch (error) {
      console.log(`error going back to input | ${error}`);
    }
  };

  return (
    <div className='sign-up-container'>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h1>Sign up a new user </h1>
        <div style={{ padding: 20, marginTop: 20, marginRight: 20 }}>
          <button type='submit' onClick={handleBackToInput}>
            Back to Input Page
          </button>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div
          style={{ paddingTop: 20, paddingBottom: 10, paddingLeft: 20 }}
          className='sign-up-email'
        >
          <input
            type='email'
            placeholder='Email'
            value={email}
            onChange={(e) => updateEmail(e.target.value)}
          ></input>
        </div>
        <div style={{ paddingLeft: 20 }} className='sign-up-password'>
          <input
            type='password'
            placeholder='Password'
            value={password}
            onChange={(e) => updatePassword(e.target.value)}
          ></input>
        </div>
        <div style={{ padding: 20 }}>
          <button type='submit'>Sign Up</button>
          {error && <h2 style={{ color: "red" }}>{error}</h2>}
        </div>
      </form>
    </div>
  );
};

export default SignUp;
