import { useState } from "react";
import SplatterBlack from "../assets/pollock_project_splatter_logo.png";
import SplatterBLue from "../assets/pollock_project_splatter_logo_blue.png";
import { Link } from "react-router-dom";
import "../CSS/responsive.css";

const SplatterLogo = ({ background }) => {
  const [currentSplatterColor, setCurrentSplatterColor] =
    useState(SplatterBlack);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <div
      style={{
        flexDirection: "column",
        alignItems: "center",
        paddingLeft: "30px",
        paddingTop: "20px",
        position: "relative",
        display: "inlineBlock",
      }}
    >
      <img
        alt='splatter_logo'
        src={currentSplatterColor}
        onMouseEnter={() => setCurrentSplatterColor(SplatterBLue)}
        onMouseOut={() => setCurrentSplatterColor(SplatterBlack)}
        onClick={() => setDropdownOpen(!dropdownOpen)}
        style={{
          height: "100px",
          zIndex: 0,
        }}
      />
      {dropdownOpen && (
        <div
          style={{
            width: "75px",
            position: "absolute",
            backgroundColor: background || "white",
            paddingLeft: "20px",
            paddingBottom: "10px",
          }}
        >
          <Link to='/map' style={{ textDecoration: "none" }}>
            <div
              className='text'
              style={{ paddingBottom: "1px", fontSize: "20px" }}
            >
              Map
            </div>
          </Link>
          <Link to='/lookup' style={{ textDecoration: "none" }}>
            <div
              className='text'
              style={{ paddingBottom: "1px", fontSize: "20px" }}
            >
              Works
            </div>
          </Link>
          {/* <Link to='/lookup' style={{ textDecoration: "none" }}>
            <div
              className='text'
              style={{ paddingBottom: "1px", fontSize: "20px" }}
            >
              Whole
            </div>
          </Link> */}
          <Link to='/about' style={{ textDecoration: "none" }}>
            <div
              className='text'
              style={{ paddingBottom: "1px", fontSize: "20px" }}
            >
              About
            </div>
          </Link>
          <div
            className='text'
            style={{ paddingBottom: "1px", fontSize: "20px" }}
          ></div>
        </div>
      )}
    </div>
  );
};
export default SplatterLogo;
