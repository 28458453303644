import React from "react";
import SignUpButton from "./SignUpButton";
import LogoutButton from "./LogoutButton";
import QueryTitleButton from "./QueryTitleButton";

const ButtonOrganizer = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        marginRight: 20,
      }}
    >
      <SignUpButton /> <LogoutButton /> <QueryTitleButton />
    </div>
  );
};

export default ButtonOrganizer;
