import React from "react";

const PKFoundationFooter = ({ background, fontColor, noText }) => {
  return (
    <div
      style={{
        display: "flex",
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1,
        width: "100vw",
        height: "50px",
        backgroundColor: background || "transparent",
        textAlign: "left",
        alignItems: "center",
      }}
    >
      <p
        style={{
          color: fontColor || "black",
          fontSize: "12px",
          paddingLeft: "46px",
        }}
      >
        {noText
          ? ""
          : "© The Pollock-Krasner Foundation / Artists Rights Society (ARS), New York"}
      </p>
    </div>
  );
};

export default PKFoundationFooter;
