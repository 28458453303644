import React from "react";
import PollockProjectLogo from "./PollockProjectLogo";
import HamburgerDropdown from "./HamburgerDropdown";

const MobileHeader = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "10px",
        height: "100px",
        width: "100vw",
        borderBottom: "1px solid",
      }}
    >
      <HamburgerDropdown />
      <div style={{ marginRight: "8px" }}>
        <PollockProjectLogo logoHeight={"45px"} />
      </div>
    </div>
  );
};

export default MobileHeader;
