import React from "react";

const ImageModal = ({
  returnToNull,
  individualImageUrl,
  setImagedClicked,
  setCUrrentIndex,
}) => {
  return (
    <div
      onClick={returnToNull}
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        right: "0",
        width: "100%",
        height: "100%",
        background: "rgba(27, 27, 27, 0.541)",
        display: "flex",
        alignItems: "center",
        zIndex: "10",
      }}
    >
      <img
        alt='pop_up_image'
        src={individualImageUrl}
        style={{
          display: "block",
          maxWidth: "60%",
          maxHeight: "80%",
          margin: "60px auto",
          boxShadow: "3px 5px 7px rgba(0, 0, 0, 0.5)",
        }}
      />
    </div>
  );
};

export default ImageModal;
