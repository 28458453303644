import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import backgroundImageOne from "../assets/background_001_low.jpg";
import backgroundImageTwo from "../assets/background_002_low.jpg";
import backgroundImageThree from "../assets/background_003_low.jpg";
import backgroundImageFour from "../assets/background_004_low.jpg";
import backgroundImageFive from "../assets/background_005_low.jpg";
import backgroundImageSix from "../assets/background_006_low.jpg";
import backgroundImageSeven from "../assets/background_007_low.jpg";
import backgroundImageEight from "../assets/background_008_low.jpg";
import logoImage from "../assets/pollock_project_logo_main.png";
import logoImagePin from "../assets/pollock_project_logo_main_pin.png";
import { motion } from "framer-motion";
import PKFoundationFooter from "./PKFoundationFooter";
import PollockProjectLogo from "./PollockProjectLogo";
import "../CSS/responsive.css";

const images = [
  backgroundImageOne,
  backgroundImageTwo,
  backgroundImageThree,
  backgroundImageFour,
  backgroundImageFive,
  backgroundImageSix,
  backgroundImageSeven,
  backgroundImageEight,
];

export const LandingPage = () => {
  const [currentLogoImage, setCurrentLogoImage] = useState(logoImage);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [loadedImages, setLoadedImages] = useState([]);

  useEffect(() => {
    const preloadImages = () => {
      const imagePromises = images.map((image) => {
        return new Promise((resolve) => {
          const img = new Image();
          img.src = image;
          img.onload = () => {
            resolve(image);
          };
        });
      });

      Promise.all(imagePromises).then((loadedImageUrls) => {
        setLoadedImages(loadedImageUrls);
        setIsImageLoaded(true);
      });
    };

    preloadImages();
  }, []);

  useEffect(() => {
    if (isImageLoaded) {
      const interval = setInterval(() => {
        setCurrentImageIndex((prevIndex) =>
          prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [isImageLoaded]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 50 }}
      transition={{ duration: 5 }}
    >
      <div className='desktop-wrapper'>
        <div
          style={{
            backgroundImage: `url(${loadedImages[currentImageIndex]})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: "100vw",
            height: "100vh",
            transition:
              "opacity 1.5s ease-in-out, background-image 1.5s ease-in-out",
          }}
        >
          <Link to='/lookup'>
            <img
              src={currentLogoImage}
              onMouseEnter={() => setCurrentLogoImage(logoImagePin)}
              onMouseOut={() => setCurrentLogoImage(logoImage)}
              alt='logo'
              style={{
                position: "absolute",
                height: "86px",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 1,
              }}
            />
          </Link>
          <div style={{}}>
            <PKFoundationFooter fontColor='white' />
          </div>
        </div>
      </div>
      {/* /////////////// desktop up mobile down */}
      <div className='mobile-wrapper'>
        <div
          style={{
            backgroundImage: `url(${loadedImages[currentImageIndex]})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: "100vw",
            height: "100vh",
            overflow: "hidden", // Ensure no overflow on mobile screens
            position: "relative", // Added to make absolute positioning work
            transition:
              "opacity 1.5s ease-in-out, background-image 1.5s ease-in-out",
          }}
        >
          <Link to='/lookup'>
            <div
              style={{
                position: "absolute",
                height: "86px",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 1,
              }}
            >
              <PollockProjectLogo logoHeight={"45px"} logoColor />
            </div>
          </Link>
          <div
            style={{
              position: "absolute",
              bottom: "10px", // Adjust this value to position the text as desired
              left: 0,
              width: "100%",
              textAlign: "center",
              color: "white",
              zIndex: 1,
            }}
          >
            &copy;The Pollock-Krasner Foundation / Artists Rights Society (ARS),
            New York
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default LandingPage;
