import React from "react";
import { Navigate } from "react-router-dom";
import { UserAuth } from "../context/Auth";

export const PrivateRoute = ({ children }) => {
  const { user } = UserAuth();

  if (user === undefined) return null;

  return user ? <>{children}</> : <Navigate to='/' replace />;
};

export default PrivateRoute;
