import React from "react";
import { useNavigate } from "react-router-dom";

const QueryTitleButton = () => {
  const navigate = useNavigate();

  const handleLookUp = async () => {
    try {
      navigate("/lookup");
    } catch (error) {}
  };

  return (
    <div style={{ paddingLeft: 20, paddingBottom: 20 }}>
      <button onClick={handleLookUp}>Look Up Artwork</button>
      <div style={{ display: "flex", flexDirection: "row" }}></div>
    </div>
  );
};

export default QueryTitleButton;
