import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import { AuthProvider } from "./context/Auth";
import SignIn from "./components/SignIn";
import SignUp from "./components/SignUp";
import ArtworkInput from "./components/ArtworkInput";
import ArtworkLookUp from "./components/ArtworkLookUp";
import LandingPage from "./components/LandingPage";
import { AnimatePresence } from "framer-motion";
import About from "./components/About";
import Whole from "./components/Whole";
import Map2 from "./components/Map2";
import PageNotFound from "./components/PageNotFound";

export const App = () => {
  const location = useLocation();
  return (
    <AnimatePresence mode='wait'>
      <AuthProvider>
        <Routes location={location} key={location.pathname}>
          <Route path='/' element={<SignIn />} />
          <Route path='/login' element={<SignIn />} />
          {/* <Route
            path='/'
            element={
              <PrivateRoute>
                <SignIn />
              </PrivateRoute>
            }
          /> */}
          <Route
            path='/landing'
            element={
              <PrivateRoute path='/landing'>
                <LandingPage />
              </PrivateRoute>
            }
          />
          <Route
            path='/lookup'
            element={
              <PrivateRoute path='/lookup'>
                <ArtworkLookUp />
              </PrivateRoute>
            }
          />
          <Route
            path='/whole'
            element={
              <PrivateRoute path='/whole'>
                <Whole />
              </PrivateRoute>
            }
          />
          <Route
            path='/about'
            element={
              <PrivateRoute path='/about'>
                <About />
              </PrivateRoute>
            }
          />
          <Route
            path='/map'
            element={
              <PrivateRoute path='/map'>
                <Map2 />
              </PrivateRoute>
            }
          />
          <Route
            path='/404'
            element={
              <PrivateRoute path='/404'>
                <PageNotFound />
              </PrivateRoute>
            }
          />
          <Route path='*' element={<Navigate to='/404' />} />
          <Route
            path='/input'
            element={
              <PrivateRoute path='/input'>
                <ArtworkInput />
              </PrivateRoute>
            }
          />
          <Route
            path='/signup'
            element={
              <PrivateRoute path='/signup'>
                <SignUp />
              </PrivateRoute>
            }
          />
        </Routes>
      </AuthProvider>
    </AnimatePresence>
  );
};
export default App;
