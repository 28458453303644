import React from "react";
import pollockSmoking from "../assets/pollock_smoking.png";

const PageNotFound = () => {
  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 1,
      }}
    >
      <img alt='pollock_404' src={pollockSmoking} />
      <h1>404 Page Not Found</h1>
    </div>
  );
};
export default PageNotFound;
