import React, { useState } from "react";
import jsonp from "jsonp";

const NewsletterInput = () => {
  const [email, setEmail] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    const url = process.env.REACT_APP_MAILCHIMP_URL;
    jsonp(`${url}&EMAIL=${email}`, { param: "c" }, (_, { msg }) => {
      alert(msg);
      setEmail("");
    });
  };

  return (
    <>
      <div>
        <form onSubmit={onSubmit}>
          <input
            type='email'
            value={email}
            placeholder='Join Newsletter'
            onChange={(e) => setEmail(e.target.value)}
          />
          <button type='submit' style={{ marginLeft: "10px" }}>
            Subscribe
          </button>
        </form>
      </div>
    </>
  );
};

export default NewsletterInput;
